import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSinglePickerDialogComponent } from './user-single-picker-dialog.component';
import { MaterialModule } from '@app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserAvatarModule } from '@app/shared/user-avatar/user-avatar.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UserSinglePickerDialogComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, UserAvatarModule, TranslateModule],
  exports: [UserSinglePickerDialogComponent],
})
export class UserSinglePickerDialogModule {}
